import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MediaSwiper from '@/component/MediaSwiper.tsx';

export interface PostPhotosProps {
  photos: string[];
  videos?: string[];
}

const PostMedia: FC<PostPhotosProps> = ({ photos: photoUrls, videos: videoUrls }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  useEffect(() => {
    if (photoUrls?.length === 0) {
      setLoading(false);
      return;
    }

    const loadImage = (url: string): Promise<string> => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(url);
        img.onerror = reject;
      });
    };

    const loadAllImages = async (): Promise<void> => {
      try {
        const loadedUrls = await Promise.all(
          photoUrls?.map((photoUrl: string) => {
            return loadImage(photoUrl);
          })
        );
        setLoadedImages(loadedUrls);
      } catch (error) {
        console.error('Error loading images:', error);
      } finally {
        setLoading(false);
      }
    };

    loadAllImages();
  }, [photoUrls]);

  return (
    <>
      <div className="relative w-full h-[33rem]">
        {loading && (
          <div className="absolute inset-0 flex justify-center items-center bg-gray-100 bg-opacity-50 z-10">
            <div className="text-tgui-hint text-md">{t('loading')}</div>
          </div>
        )}
        {!loading && <MediaSwiper photoUrls={loadedImages} videoUrls={videoUrls} />}
      </div>
    </>
  );
};

export default PostMedia;
