import {FC, useCallback, useRef, useState} from 'react';
import { Post, PostFilter } from '../model/post.model.ts';
import 'swiper/css';
import 'swiper/css/pagination';
import { List, Spinner } from '@telegram-apps/telegram-ui';
import { useTranslation } from 'react-i18next';
import { usePostService } from '@/service/post.service.ts';
import PostListItem from './PostListItem.tsx';
import useEffectAfterMount from '@/hook/useEffectAfterMount.tsx';
import _ from 'lodash';

type PostListProps = {
  filter: PostFilter;
};

const LIMIT = 7;
const THRESHOLD_INDEX = 2;

const PostList: FC<PostListProps> = (props) => {
  const { t } = useTranslation();
  const postService = usePostService();

  const [currentFilter, setCurrentFilter] = useState<PostFilter>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [posts, setPosts] = useState<Post[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentOffset, setCurrentOffset] = useState<number>(0);

  const observer = useRef<IntersectionObserver | null>(null);

  const loadPosts = useCallback(
    async (postFilter: PostFilter, offset: number) => {
      setLoading(true);
      try {
        const posts = await postService.findPosts({
          filter: postFilter,
          pagination: { offset, limit: LIMIT },
        });

        setCurrentFilter(postFilter);

        if (offset === 0) {
          setPosts(posts);
          setCurrentOffset(LIMIT);
          setHasMore(posts.length === LIMIT);
        } else {
          setPosts((prev) => [...prev, ...posts]);
          setCurrentOffset((offset) => offset + LIMIT);
          setHasMore(!!posts.length);
        }
      } finally {
        setLoading(false);
      }
    },
    [postService, currentFilter]
  );

  useEffectAfterMount(() => {
    if (_.isEmpty(props.filter) || !_.isEqual(props.filter, currentFilter)) {
      setPosts([]);
      loadPosts(props.filter, 0).catch(console.error);
    }
  }, [props.filter]);

  const lastPostRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || !hasMore) {
        return;
      }

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadPosts(currentFilter, currentOffset).catch(console.error);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadPosts, currentOffset, loading, hasMore]
  );

  return (
    <List className="w-full">
      {!loading && !posts?.length && (
        <div className="absolute inset-0 flex items-center justify-center text-lg text-tgui-hint">{t('no_data')}</div>
      )}

      {posts?.map((post, index) => (
        <PostListItem
          key={post.id}
          post={post}
          ref={posts.length >= LIMIT && index === posts.length - THRESHOLD_INDEX ? lastPostRef : null}
        ></PostListItem>
      ))}

      {loading && (
        <div className="flex justify-center w-full py-4">
          <Spinner size="m" />
        </div>
      )}
    </List>
  );
};

export default PostList;
