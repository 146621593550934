import { forwardRef, memo } from 'react';
import { getPeriodTitle, Post, UNIT_TYPE } from '@/model/post.model.ts';
import { Section } from '@telegram-apps/telegram-ui';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import ExpandableDescription from '@/component/ExpandableDescription.tsx';
import PostMedia from '@/component/PostMedia.tsx';

export type PostListItemProps = {
  post: Post;
};

const PostListItem = forwardRef<HTMLDivElement, PostListItemProps>(({ post }, ref) => {
  const { t, i18n } = useTranslation();

  let contactUrl;
  if (post.user.username) {
    contactUrl = `https://t.me/${post.user.username}`;
  } else if (post.features.telegram) {
    contactUrl = `https://wa.me/${post.features.telegram}`;
  } else if (post.features.whatsapp) {
    contactUrl = `https://wa.me/${post.features.whatsapp}`;
  }

  return (
    <div ref={ref}>
      <Section key={post.id} className="rounded-xl shadow-md overflow-hidden">
        <div className="flex flex-col gap-2 py-2">
          <div className="flex items-center w-full px-2 text-lg font-bold tracking-wider">
            <div className="flex-grow truncate">{post.features?.location || '-'}</div>
            <div className="flex gap-1 items-baseline">
              <div className="text-xl font-bold">IDR {post.features?.pricePerMonth || '-'}</div>
              <div className="text-sm text-tgui-hint">
                {t('mil')}/{t('month_abbr')}
              </div>
            </div>
          </div>

          <PostMedia photos={post?.photosUrls} videos={post?.videosUrls} />

          <div className="flex px-2">
            <div className="flex flex-grow items-center">
              {/*<PiDotsThreeCircleLight size={27}/>*/}
              {/*<div className="flex items-center rounded-full px-2"><PiChatCircleTextThin size={27}/>1</div>*/}
            </div>

            {contactUrl && (
              <a href={contactUrl} className="bg-tgui-button text-tgui-button-text rounded-full px-2 py-1">
                {t('contact')}
              </a>
            )}
          </div>

          <div className="flex flex-col px-2 gap-2">
            <div className="grid grid-cols-2 gap-2 w-full">
              <div className="flex gap-2">
                <div className="text-tgui-hint">{t('type')}:</div>
                {post.features.type === UNIT_TYPE.ROOM ? t('room') : t('villa')}
              </div>
              <div className="flex gap-2">
                <div className="text-tgui-hint">{t('rooms')}:</div>
                {post.features.rooms || '-'}
              </div>
              <div className="flex gap-2">
                <div className="text-tgui-hint">{t('from')}:</div>
                {post.features?.from ? moment(post.features?.from).format('DD MMM YYYY') : '-'}
              </div>
              <div className="flex gap-2">
                <div className="text-tgui-hint">{t('to')}:</div>
                {post.features?.to ? moment(post.features?.to).format('DD MMM YYYY') : '-'}
              </div>
              <div className="flex gap-2">
                <div className="text-tgui-hint">{t('minimal_period')}:</div>
                {post.features?.minimalPeriod ? t(getPeriodTitle(post.features?.minimalPeriod)) : '-'}
              </div>
            </div>

            <ExpandableDescription
              description={i18n.language === 'ru' ? post.description : post.features?.englishDescription}
            />

            <div className="flex w-full justify-end text-tgui-hint text-sm">
              {moment(post.timestamp).format('DD-MM-YYYY HH:mm')}
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
});

export default memo(PostListItem);
