import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ExpandableDescriptionProps {
  description: string;
  maxLength?: number;
}

const ExpandableDescription: FC<ExpandableDescriptionProps> = ({ description, maxLength = 110 }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  if (description.length <= maxLength) {
    return <div>{description}</div>;
  }

  return (
    <div>
      {isExpanded ? description : `${description.slice(0, maxLength)}...`}
      <button onClick={toggleExpand} className="text-tgui-accent-text hover:underline ml-1">
        {isExpanded ? t('less') : t('more')}
      </button>
    </div>
  );
};

export default ExpandableDescription;
