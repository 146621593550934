import { Modal } from '@telegram-apps/telegram-ui';
import { FC, PropsWithChildren } from 'react';

type ModalPanelProps = PropsWithChildren<{
  title: string;
  onClose: () => void;
}>;

const ModalPanel: FC<ModalPanelProps> = ({ title, onClose, children }) => {
  return (
    <Modal
      open={true}
      onOpenChange={(open) => !open && onClose()}
      header={
        <Modal.Header>
          {/*<VisuallyHidden>*/}
          {/*  <DialogTitle>{title}</DialogTitle>*/}
          {/*</VisuallyHidden>*/}
          <div className="text-xl mt-2">{title}</div>
        </Modal.Header>
      }
      className="mx-2 pb-8 min-h-full !z-10"
    >
      {children}
    </Modal>
  );
};

export default ModalPanel;
