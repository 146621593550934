import { create } from 'zustand';
import { Post, PostFilterOptions, PostFilterValues, UserPostFilter } from '../model/post.model.ts';
import axios, { AxiosInstance } from 'axios';
import WebApp from '@twa-dev/sdk';

export const usePostService = (): PostService => {
  return usePostStore((state) => state.postService);
};

export interface PostService {
  findPosts(options: PostFilterOptions): Promise<Post[]>;
  getPostFilterValues(): Promise<PostFilterValues>;
  getUserFilter(username: string): Promise<UserPostFilter | null>;
}

export class PostServiceImpl implements PostService {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: import.meta.env.VITE_APP_API_PATH,
    });
  }

  async findPosts(options: PostFilterOptions): Promise<Post[]> {
    options.username = WebApp.initDataUnsafe?.user?.username;
    const { data } = await this.httpClient.post('/post/filter', options);
    return data;
  }

  async getPostFilterValues(): Promise<PostFilterValues> {
    const { data } = await this.httpClient.get('/post/filter/values');
    return data;
  }

  async getUserFilter(username: string): Promise<UserPostFilter | null> {
    if (!username) {
      return null;
    }

    const { data } = await this.httpClient.get(`/post/filter/saved?username=${username}`);
    return data;
  }
}

const usePostStore = create(() => ({
  postService: new PostServiceImpl(),
}));
