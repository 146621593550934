import { FC, useState } from 'react';
import ModalPanel from '@/component/ModalPanel.tsx';
import { Button, FixedLayout, Section, Slider } from '@telegram-apps/telegram-ui';

export type FilterSliderProps = {
  title: string;
  range: boolean;
  min?: number;
  max?: number;
  step?: number;
  unit: string;
  defaultValue: number | [number, number];
  onValueSelect: (value: number | [number, number] | null) => void;
  onClose: () => void;
};

const FilterSlider: FC<FilterSliderProps> = ({
  title,
  range,
  min,
  max,
  step,
  unit,
  defaultValue,
  onValueSelect,
  onClose,
}) => {
  const [value, setValue] = useState<number | [number, number]>(defaultValue);

  return (
    <ModalPanel title={title} onClose={onClose}>
      <div className="flex flex-col gap-8">
        <Section className="flex h-48 justify-center items-center">
          <div className="w-full text-3xl flex flex-col items-center">
            <p>{`${Array.isArray(value) ? value[0] + ' - ' + value[1] : value}`}</p>
            <p>{unit}</p>
          </div>
        </Section>
        {range ? (
          <Slider
            min={min}
            max={max}
            step={step}
            multiple
            defaultValue={defaultValue as [number, number]}
            onChange={setValue}
          />
        ) : (
          <Slider min={min} max={max} step={step} defaultValue={defaultValue as number} onChange={setValue} />
        )}
      </div>
      <FixedLayout vertical="bottom" className="!pb-10">
        <Button
          className="w-full"
          onClick={() => {
            if (Array.isArray(value) && (value[0] !== min || value[1] !== max) && defaultValue !== value) {
              onValueSelect(value);
            } else if (!Array.isArray(value) && defaultValue !== value) {
              onValueSelect(value);
            } else {
              onValueSelect(null);
            }

            onClose();
          }}
        >
          Done
        </Button>
      </FixedLayout>
    </ModalPanel>
  );
};

export default FilterSlider;
