import { FC, useState } from 'react';
import { Button, Cell, Checkbox, Divider, FixedLayout, List, Radio } from '@telegram-apps/telegram-ui';
import ModalPanel from '@/component/ModalPanel.tsx';
import { useTranslation } from 'react-i18next';

export type ModalSelectOption = {
  label: string;
  value: string | number;
};

export type ModalSelectProps = {
  title: string;
  multi?: boolean;
  selected: ModalSelectOption[];
  options: ModalSelectOption[];
  onSelect: (options: ModalSelectOption[] | null) => void;
  onClose: () => void;
};

const DividerLine = () => <Divider className="bg-tgui-divider h-[1px]" />;

const ModalSelect: FC<ModalSelectProps> = ({ title, multi = false, selected = [], options, onSelect, onClose }) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<ModalSelectOption[]>(selected);

  return (
    <ModalPanel title={title} onClose={onClose}>
      <List className="flex flex-col gap-2 h-[calc(100vh-10rem)] overflow-y-scroll">
        <Cell key={0} Component="label" className="h-5" onClick={() => onSelect(null)}>
          <div className="text-xl text-tgui-accent-text">{t('show_all')}</div>
        </Cell>
        <DividerLine key={1} />
        {options.map((option, i) => (
          <>
            <Cell
              key={i + 2}
              Component="label"
              className="h-5"
              before={
                multi ? (
                  <Checkbox
                    name={option.label}
                    value={option.value}
                    defaultChecked={selectedOptions.findIndex((o) => o.value === option.value) !== -1}
                    onChange={() =>
                      setSelectedOptions((prev) => {
                        const newOptions = [...prev];
                        const index = newOptions.findIndex((o) => o.value === option.value);
                        if (index !== -1) {
                          newOptions.splice(index, 1);
                        } else {
                          newOptions.push(option);
                        }
                        return newOptions;
                      })
                    }
                  />
                ) : (
                  <Radio
                    name={option.label}
                    value={option.value}
                    defaultChecked={selectedOptions.findIndex((o) => o.value === option.value) !== -1}
                    onClick={() => onSelect([option])}
                  />
                )
              }
            >
              <div className="text-xl">{option.label}</div>
            </Cell>
            <DividerLine key={i + 3} />
          </>
        ))}
      </List>
      {multi && (
        <FixedLayout vertical="bottom" className="!pb-10">
          <Button className="w-full" onClick={() => onSelect(selectedOptions)}>
            {t('done')}
          </Button>
        </FixedLayout>
      )}
    </ModalPanel>
  );
};

export default ModalSelect;
