import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { AppRoot } from '@telegram-apps/telegram-ui';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '@/assets/en.json';
import ru from '@/assets/ru.json';
import WebApp from '@twa-dev/sdk';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppRoot>
      <App />
    </AppRoot>
  </React.StrictMode>
);

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    ru: { translation: ru },
  },
  lng: WebApp.initDataUnsafe?.user?.language_code,
  fallbackLng: 'ru',
});

WebApp.onEvent('themeChanged', () => (document.documentElement.className = WebApp.colorScheme));
WebApp.setHeaderColor('secondary_bg_color');
WebApp.setBackgroundColor('secondary_bg_color');
WebApp.disableVerticalSwipes();
WebApp.expand();
WebApp.ready();
