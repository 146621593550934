import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

function useEffectAfterMount(effect: EffectCallback, deps?: DependencyList) {
  const isFirstRender = useRef(true);
  const isProd = import.meta.env.PROD;
  const cleanupRef = useRef<void | (() => void)>();

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (isProd) {
        cleanupRef.current = effect();
      }
    } else {
      cleanupRef.current = effect();
    }

    return () => {
      if (typeof cleanupRef.current === 'function') {
        cleanupRef.current();
      }
    };
  }, deps);
}

export default useEffectAfterMount;