import { RiArrowDropDownLine } from 'react-icons/ri';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

type FilterButtonProps = {
  title: string;
  value?: string | number;
  onClick?: () => void;
};

const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(({ title, value, onClick }, ref) => {
  const { t } = useTranslation();
  return (
    <button
      ref={ref}
      className="flex h-full items-center py-1 pl-2 border border-tgui-outline rounded-xl bg-tgui-tertiary-bg"
      onClick={onClick}
    >
      <div className="flex flex-col items-start">
        <div className="text-sm text-tgui-hint">{title}</div>
        <div className="max-w-28 text-sm font-semibold truncate">{value || t('all')}</div>
      </div>
      <RiArrowDropDownLine size={30} className="fill-tgui-hint" />
    </button>
  );
});

export default FilterButton;
