import { useState } from 'react';
import { PostFilter } from '@/model/post.model.ts';
import PostFilters from '@/component/PostFilters.tsx';
import PostList from '@/component/PostList.tsx';
import { FixedLayout } from '@telegram-apps/telegram-ui';
import _ from 'lodash';

function App() {
  const [postFilter, setPostFilter] = useState<PostFilter>({});

  return (
    <div className="relative min-w-screen min-h-screen bg-tgui-secondary-bg">
      <FixedLayout vertical="top" className="h-12 z-10 mb-2">
        <PostFilters filter={postFilter} onFilterChange={(filter) => setPostFilter(_.pickBy(filter, _.identity))} />
      </FixedLayout>
      <div className="pt-14">
        <PostList filter={postFilter} />
      </div>
    </div>
  );
}

export default App;
