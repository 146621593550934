import { User } from '@/model/user.model.ts';

export enum UNIT_TYPE {
  ROOM = 'ROOM',
  VILLA = 'VILLA',
}

export enum PERIOD {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const getPeriodTitle = (period?: PERIOD): string => {
  if (!period) {
    return '-';
  }

  switch (period) {
    case PERIOD.DAY:
      return 'day';
    case PERIOD.WEEK:
      return 'week';
    case PERIOD.MONTH:
      return 'month';
    case PERIOD.YEAR:
      return 'year';
    default:
      return '-';
  }
};

export type PostPagination = {
  offset?: number;
  limit?: number;
};

export type PostFilterValues = {
  locations?: string[];
  type?: string[];
};

export type UserPostFilter = {
  username: string;
  filter: PostFilter;
};

export type PostFilterOptions = {
  username?: string;
  filter: PostFilter | null;
  pagination?: PostPagination;
};

export type PostFilter = {
  locations?: string[];
  type?: UNIT_TYPE;
  roomsRangeStart?: number;
  roomsRangeEnd?: number;
  priceRangeStart?: number;
  priceRangeEnd?: number;
};

export type PostFeatures = {
  type?: UNIT_TYPE;
  rooms?: number;
  from?: Date;
  to?: Date;
  location?: string;
  pricePerMonth?: string;
  minimalPeriod?: PERIOD;
  whatsapp: string;
  telegram: string;
  englishDescription: string;
};

export type Post = {
  id: string;
  user: User;
  timestamp: Date;
  features: PostFeatures;
  description: string;
  photosUrls: string[];
  videosUrls: string[];
};
